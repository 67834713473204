const ENV = 'staging'

const configs = {
  local: {
    env: 'development',
    eduServer: 'http://localhost:8080',
    graphqlEndpoint: 'http://localhost:8080/graphql',
    url: 'http://localhost:8000',
    oauthEndpoint: 'https://oauth.nightzookeeper.com',
    fileServer: 'https://file-server.nightzookeeper.com/upload',
    dictEndpoint: 'https://content.nightzookeeper.com/graphql',
    graphqlSchemaVersion: 5, // Bumb when schema changes
    apollo: {
      connectToDevTools: true
    }
  },
  staging: {
    env: 'staging',
    eduServer: 'https://gql-staging.nightzookeeper.com',
    graphqlEndpoint: 'https://gql-staging.nightzookeeper.com/graphql',
    oauthEndpoint: 'https://oauth.nightzookeeper.com',
    fileServer: 'https://file-server.nightzookeeper.com/upload',
    url: 'https://staging.nightzookeeper.com',
    dictEndpoint: 'https://content.nightzookeeper.com/graphql',
    graphqlSchemaVersion: 5, // Bumb when schema changes
    apollo: {
      connectToDevTools: true
    }
  },
  production: {
    env: 'production',
    eduServer: 'https://graphql.nightzookeeper.com',
    graphqlEndpoint: 'https://graphql.nightzookeeper.com/graphql',
    fileServer: 'https://file-server.nightzookeeper.com/upload',
    oauthEndpoint: 'https://oauth.nightzookeeper.com',
    dictEndpoint: 'https://content.nightzookeeper.com/graphql',
    url: 'https://nightzookeeper.com',
    graphqlSchemaVersion: 5, // Bumb when schema changes
    apollo: {
      connectToDevTools: true
    }
  }
}


export default configs[ENV]
