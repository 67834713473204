import { InMemoryCache, ApolloLink } from 'apollo-boost'
import ApolloClient from 'apollo-client'
import { from } from 'apollo-link'
import localForage from 'localforage'
import { CachePersistor } from 'apollo-cache-persist'
import { setContext } from "apollo-link-context"
import { HttpLink } from 'apollo-link-http'
import fetch from 'unfetch'
import config from '../config'

const httpLink = new HttpLink({
  uri: config.graphqlEndpoint,
  fetch: fetch
})

const dictHttpLink =  new HttpLink({
  uri: config.dictEndpoint,
  fetch,
})

const authLink = setContext((operation, { headers }) =>
    typeof window !== 'undefined' && localForage.getItem('token').then(token => {
      if (token) {
        return {
          headers: {
            authorization: `Bearer ${token.value}`
          }
        }
      }
    })
)

const cache = new InMemoryCache()

const persistor = new CachePersistor({
  cache,
  storage: localForage,
  debug: config.env === 'development',
  trigger: 'write',
  maxSize: false 
})

if (typeof window !== 'undefined') {
  cache.persistor = persistor
  cache.persistor.restore()
}

export const client = new ApolloClient({
  link: from([
    authLink,
    ApolloLink.split(
      operation => operation.getContext().clientName === 'content',
      dictHttpLink,
      httpLink
    )
  ]),
  cache
})
