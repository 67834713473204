import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import config from '../../config'
import localforage from 'localforage'

const initialState = {
  isAuthenticated: null,
  currentUser: null
}

const useCurrentUser = () => {
  let [isAuthenticated, setIsAuthenticated] = useState(
    initialState.isAuthenticated
  )
  let [currentUser, setCurrentUser] = useState(initialState.currentUser)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const token = await localforage.getItem('token')
    if (token && token.value && new Date(token.expiresAt) > new Date()) {
      return onLoginSuccess()
    } else {
      await localforage.removeItem('token')
      setIsAuthenticated(false)
      setCurrentUser(null)
    }
  }

  const login = async (email, password) => {
    const token = await localforage.getItem('token')
    if (token) return onLoginSuccess()
    const body = {
      'client_id': '6495b15a2cb8503abe6113b07579e3df',
      'grant_type': 'password',
      'username': email,
      'password': password
    }
    const URLSearchParams = Object.keys(body).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(body[key])
    }).join('&')
    const stream = await fetch(`${config.oauthEndpoint}/oauth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: URLSearchParams
    })
    const data = await stream.json()
    onLoginSuccess(data)
  }

  const onLoginSuccess = (data) => {
    if (data && data.user.type !== 'tutor') return
    setIsAuthenticated(true)
    if (!data) return
    storeToken(data)
    setCurrentUser(data.user)
  }

  const storeToken = async ({ accessToken, accessTokenExpiresAt }) => {
    await localforage.setItem('token', { value: accessToken, expiresAt: accessTokenExpiresAt })
    return true
  }

  const logout = () => {
    setCurrentUser(null)
    setIsAuthenticated(false)
  }

  const updateCurrentUser = newUser => {
    setCurrentUser(newUser)
  }

  return { isAuthenticated, currentUser, login, logout, updateCurrentUser }
}

export const CurrentUserState = createContainer(useCurrentUser)
