import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import { client } from "./src/data/apolloClient"
import { Grommet } from "grommet"
import theme from "./src/theme"
import { AppState } from "./src/components/state/AppState"
import { CurrentUserState } from "./src/components/state/CurrentUserState"
import ModalManager from "./src/components/ModalManager/ModalManager"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// export const wrapPageElement = ({ element, props }) => {
//   // props provide same data to Layout as Page element will get
//   // including location, data, etc - you don't need to pass it
//   return (
//     <GroupingCreatorState.Provider>{element}</GroupingCreatorState.Provider>
//   )
// }

export const wrapRootElement = ({ element }) => (
  <>
    <ApolloProvider client={client}>
      <CurrentUserState.Provider>
        <AppState.Provider>
          <Grommet theme={theme}>
            <ModalManager>{element}</ModalManager>
          </Grommet>
        </AppState.Provider>
      </CurrentUserState.Provider>
    </ApolloProvider>
  </>
)
