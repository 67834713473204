export const colors = {
  lightGray: '#E3E8EE',
  indigo: '#556CD6',
  darkPurple: '#191F36',
  green: '#1FA672',
  red: '#E56F4A',
  darkBlue: '#3C4257',
  gray: '#6F798B',
  lighterGray: '#F8FAFC'
}

export const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px'
    },
    colors: {
      primary: colors.indigo,
      brand: '#556CD6',
      brandText: '#1A1F36',
      text: {
        light: colors.darkPurple
      }
    },
    table: {
      header: {
        background: colors.lighterGray,
        border: 'bottom'
      },
      body: {
        border: 'bottom'
      }
    }
  },
  checkbox: {
    color: 'blue',
    border: {
      color: 'red'
    }
  },
  button: {
    border: {
      radius: '5px',
      color: colors.indigo
    },
    primary: {
      color: colors.indigo
    },
    extend: props => {
      let extraStyles = ''
      if (props.primary) {
        extraStyles = `
        text-transform: uppercase;
        color: white;
        padding: 6px 12px;
          `
      }
      return `
          font-size: 12px;
          font-weight: bold;
          ${extraStyles}
        `
    }
  }
}

export default theme
