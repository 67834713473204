import { useState } from "react"
import { createContainer } from "unstated-next"

const initialState = {
  navBarOpened: false,
  yearGroup: 1,
  selectedCurriculumGroup: {}
}

const useAppState = () => {
  let [navBarOpened, setNavBarOpened] = useState(initialState.navBarOpened)
  let [yearGroup, setYearGroup] = useState(initialState.yearGroup)
  const [selectedCurriculumGroup, selectCurriculumGroup] = useState(initialState.selectedCurriculumGroup)

  const toggleNavBar = () => {
    setNavBarOpened(!navBarOpened)
  }

  const selectYearGroup = yearGroup => {
    if (yearGroup < 1 || yearGroup > 6) return
    setYearGroup(yearGroup)
  }

  return {
    navBarOpened,
    toggleNavBar,
    setNavBarOpened,
    yearGroup,
    selectYearGroup,
    selectedCurriculumGroup,
    selectCurriculumGroup
  }
}

export const AppState = createContainer(useAppState)
