// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenges-definitions-js": () => import("./../../../src/pages/challenges/definitions.js" /* webpackChunkName: "component---src-pages-challenges-definitions-js" */),
  "component---src-pages-challenges-gallery-js": () => import("./../../../src/pages/challenges/gallery.js" /* webpackChunkName: "component---src-pages-challenges-gallery-js" */),
  "component---src-pages-challenges-grouping-2-js": () => import("./../../../src/pages/challenges/grouping2.js" /* webpackChunkName: "component---src-pages-challenges-grouping-2-js" */),
  "component---src-pages-challenges-grouping-js": () => import("./../../../src/pages/challenges/grouping.js" /* webpackChunkName: "component---src-pages-challenges-grouping-js" */),
  "component---src-pages-challenges-jumbled-sentence-js": () => import("./../../../src/pages/challenges/jumbled-sentence.js" /* webpackChunkName: "component---src-pages-challenges-jumbled-sentence-js" */),
  "component---src-pages-challenges-jumbled-word-js": () => import("./../../../src/pages/challenges/jumbled-word.js" /* webpackChunkName: "component---src-pages-challenges-jumbled-word-js" */),
  "component---src-pages-challenges-long-writing-js": () => import("./../../../src/pages/challenges/long-writing.js" /* webpackChunkName: "component---src-pages-challenges-long-writing-js" */),
  "component---src-pages-challenges-matching-js": () => import("./../../../src/pages/challenges/matching.js" /* webpackChunkName: "component---src-pages-challenges-matching-js" */),
  "component---src-pages-challenges-missing-word-js": () => import("./../../../src/pages/challenges/missing-word.js" /* webpackChunkName: "component---src-pages-challenges-missing-word-js" */),
  "component---src-pages-challenges-missing-word-typing-js": () => import("./../../../src/pages/challenges/missing-word-typing.js" /* webpackChunkName: "component---src-pages-challenges-missing-word-typing-js" */),
  "component---src-pages-challenges-namings-js": () => import("./../../../src/pages/challenges/namings.js" /* webpackChunkName: "component---src-pages-challenges-namings-js" */),
  "component---src-pages-challenges-paragraphs-js": () => import("./../../../src/pages/challenges/paragraphs.js" /* webpackChunkName: "component---src-pages-challenges-paragraphs-js" */),
  "component---src-pages-challenges-quiz-js": () => import("./../../../src/pages/challenges/quiz.js" /* webpackChunkName: "component---src-pages-challenges-quiz-js" */),
  "component---src-pages-challenges-reading-comprehension-js": () => import("./../../../src/pages/challenges/reading-comprehension.js" /* webpackChunkName: "component---src-pages-challenges-reading-comprehension-js" */),
  "component---src-pages-challenges-short-writing-js": () => import("./../../../src/pages/challenges/short-writing.js" /* webpackChunkName: "component---src-pages-challenges-short-writing-js" */),
  "component---src-pages-challenges-word-rap-js": () => import("./../../../src/pages/challenges/word-rap.js" /* webpackChunkName: "component---src-pages-challenges-word-rap-js" */),
  "component---src-pages-challenges-word-search-js": () => import("./../../../src/pages/challenges/word-search.js" /* webpackChunkName: "component---src-pages-challenges-word-search-js" */),
  "component---src-pages-create-definitions-js": () => import("./../../../src/pages/create/definitions.js" /* webpackChunkName: "component---src-pages-create-definitions-js" */),
  "component---src-pages-create-gallery-js": () => import("./../../../src/pages/create/gallery.js" /* webpackChunkName: "component---src-pages-create-gallery-js" */),
  "component---src-pages-create-grouping-2-js": () => import("./../../../src/pages/create/grouping2.js" /* webpackChunkName: "component---src-pages-create-grouping-2-js" */),
  "component---src-pages-create-grouping-js": () => import("./../../../src/pages/create/grouping.js" /* webpackChunkName: "component---src-pages-create-grouping-js" */),
  "component---src-pages-create-jumbled-sentence-js": () => import("./../../../src/pages/create/jumbled-sentence.js" /* webpackChunkName: "component---src-pages-create-jumbled-sentence-js" */),
  "component---src-pages-create-jumbled-word-js": () => import("./../../../src/pages/create/jumbled-word.js" /* webpackChunkName: "component---src-pages-create-jumbled-word-js" */),
  "component---src-pages-create-learning-goal-js": () => import("./../../../src/pages/create/learning-goal.js" /* webpackChunkName: "component---src-pages-create-learning-goal-js" */),
  "component---src-pages-create-long-writing-js": () => import("./../../../src/pages/create/long-writing.js" /* webpackChunkName: "component---src-pages-create-long-writing-js" */),
  "component---src-pages-create-matching-js": () => import("./../../../src/pages/create/matching.js" /* webpackChunkName: "component---src-pages-create-matching-js" */),
  "component---src-pages-create-missing-word-js": () => import("./../../../src/pages/create/missing-word.js" /* webpackChunkName: "component---src-pages-create-missing-word-js" */),
  "component---src-pages-create-missing-word-typing-js": () => import("./../../../src/pages/create/missing-word-typing.js" /* webpackChunkName: "component---src-pages-create-missing-word-typing-js" */),
  "component---src-pages-create-namings-js": () => import("./../../../src/pages/create/namings.js" /* webpackChunkName: "component---src-pages-create-namings-js" */),
  "component---src-pages-create-paragraphs-js": () => import("./../../../src/pages/create/paragraphs.js" /* webpackChunkName: "component---src-pages-create-paragraphs-js" */),
  "component---src-pages-create-quiz-js": () => import("./../../../src/pages/create/quiz.js" /* webpackChunkName: "component---src-pages-create-quiz-js" */),
  "component---src-pages-create-reading-comprehension-js": () => import("./../../../src/pages/create/reading-comprehension.js" /* webpackChunkName: "component---src-pages-create-reading-comprehension-js" */),
  "component---src-pages-create-short-writing-js": () => import("./../../../src/pages/create/short-writing.js" /* webpackChunkName: "component---src-pages-create-short-writing-js" */),
  "component---src-pages-create-word-rap-js": () => import("./../../../src/pages/create/word-rap.js" /* webpackChunkName: "component---src-pages-create-word-rap-js" */),
  "component---src-pages-create-word-search-js": () => import("./../../../src/pages/create/word-search.js" /* webpackChunkName: "component---src-pages-create-word-search-js" */),
  "component---src-pages-curriculum-editor-js": () => import("./../../../src/pages/curriculum-editor.js" /* webpackChunkName: "component---src-pages-curriculum-editor-js" */),
  "component---src-pages-curriculum-group-js": () => import("./../../../src/pages/curriculum-group.js" /* webpackChunkName: "component---src-pages-curriculum-group-js" */),
  "component---src-pages-curriculum-groups-js": () => import("./../../../src/pages/curriculum-groups.js" /* webpackChunkName: "component---src-pages-curriculum-groups-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-goal-js": () => import("./../../../src/pages/learning-goal.js" /* webpackChunkName: "component---src-pages-learning-goal-js" */),
  "component---src-pages-learning-goals-js": () => import("./../../../src/pages/learning-goals.js" /* webpackChunkName: "component---src-pages-learning-goals-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

